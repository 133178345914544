/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 09.10.2015, 11:39:27
    Author     : csh
*/

div.top-navigation-flyout-arrow {
    display: none;
    position: relative;

    /* Hintergrund Pfeil */
    height: 22px;
    background-image: URL("../images/hays_menu/top-menu-arrow-white.gif");
    background-position-x: calc(50% - 40px);
    background-position-y: -9px;
    background-repeat: no-repeat;
}

ul li.top-navigation-flyout-link:hover > div.top-navigation-flyout-arrow {
    display: block;
}
